<template>
  <el-dialog
    title="請問是否已經有 Ivy-way 的會員了？"
    :visible.sync="visible"
    width="560px"
    :before-close="() => {}">
    <div class="content">
      <div
        :class="`pointer options ${hasIvyMember ? 'selectedOptions' : ''}`"
        style="margin-right: 8px"
        @click="hasIvyMember = true"
      >
        <span class="icon">
          <i class="far fa-circle" />
        </span>
        <h2>有</h2>
        <p>登入後自行綁定 Line 帳號！</p>
      </div>
      <div
        :class="`pointer options ${!hasIvyMember ? 'selectedOptions' : ''}`"
        style="margin-left: 8px"
        @click="hasIvyMember = false"
      >
        <span class="icon">
          <i class="fas fa-times" />
        </span>
        <h2>沒有</h2>
        <p>註冊後自動綁定 Line 帳號！</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="() => $emit('submit', hasIvyMember)">
        {{ $t("button.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      hasIvyMember: true
    };
  },
  computed: {},
  async mounted() {},
  async created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    margin: 16px 0px;
}

.options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 4px solid #bababa;
    border-radius: 8px;
    width: 1000px;
    transition: border 0.2s ease;

    .icon {
        color: #bababa;
        font-size: 84px;
        transition: color 0.2s ease;
    }

    h2 {
        color: #bababa;
    }

    p {
        color: #bababa;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}

.selectedOptions {
    border: 4px solid #68b488;
    transition: border 0.2s ease;
    width: 1000px;

    .icon {
        color: #68b488;
        transition: color 0.2s ease;
    }

    h2 {
        color: #68b488;
    }

    p {
        font-weight: bolder;
        color: #68b488;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}
</style>
