<template>
  <div>
    <BindTypeDialog
      :visible="bindTypeDialogVisible"
      @toggleDialogVisible="(visible) => bindTypeDialogVisible = visible"
      @submit="(hasIvyMember) => {
        if (hasIvyMember) {
          $router.push({ name: 'Login' });
        } else {
          $router.push({ name: 'Register' });
        }
      }"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import BindTypeDialog from "@/components/line/BindTypeDialog";
import lineLoginApis from "@/apis/lineLogin";
import JwtService from "@/common/jwt.service";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "LineLogin - Ivy-Way Academy"
    };
  },

  components: {
    BindTypeDialog
  },

  mixins: [],

  props: [],
  data() {
    return {
      bindTypeDialogVisible: false
    };
  },
  computed: {},
  watch: {},

  async mounted() {
    this.$loading({
      lock: true,
      text: "Login...",
      spinner: "el-icon-loading"
    });

    let url = await this.$router.resolve({
      name: "LineLogin"
    });

    try {
      let loginRes = await lineLoginApis.login({
        code: this.$route.query.code,
        redirect_uri: window.location.origin + url.href
      });

      const {
        line_account_bound_ivy_way,
        line_user_id_token,
        access_token
      } = loginRes;

      if (line_account_bound_ivy_way) {
        JwtService.saveToken(access_token);
        await this.$store.dispatch("user/setToken", access_token);
        await this.loginSuccess();

        let nextRoute = JSON.parse(localStorage.getItem("lastAccessRoutePath"));
        localStorage.setItem("lastAccessRoutePath", "");

        if (!nextRoute.path || nextRoute.path === "/") {
          this.$router.push({ name: "Home" });
        } else {
          this.$router.push({
            path: nextRoute.path,
            query: nextRoute.query
          });
        }
      } else {
        this.bindTypeDialogVisible = true;
        localStorage.setItem("lineUserIdToken", line_user_id_token);
      }

    } catch (e) {
      await this.loginFailed();
    }
  },

  methods: {
    async loginSuccess() {
      await this.$message({
        message: "Login success",
        type: "success"
      });
    },
    async loginFailed() {
      await this.$message({
        message: "Login failed",
        type: "error"
      });
    }
  }
};
</script>

<style scoped></style>
