var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BindTypeDialog',{attrs:{"visible":_vm.bindTypeDialogVisible},on:{"toggleDialogVisible":(visible) => _vm.bindTypeDialogVisible = visible,"submit":(hasIvyMember) => {
      if (hasIvyMember) {
        _vm.$router.push({ name: 'Login' });
      } else {
        _vm.$router.push({ name: 'Register' });
      }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }